<template>
    <div>
        <qrcode-stream class="relative h-full" :key="_uid" :camera="camera" :track="selected.value" @init="onInit" @decode="getQr" v-if="!destroyed">
            <div class="loading-indicator absolute bottom-0 w-full flex items-start justify-center" v-if="loading" style="">
                <div class="mt-8">
                    ກຳລັງໂຫລດ...

                </div>
            </div>
        </qrcode-stream>
    </div>
</template>

<script>
export default {
    name: "ViewFinder",
    props: ["getQr", "camera"],
    mounted() {
    },

    data() {
        const options = [
            {text: "nothing (default)", value: undefined},
            {text: "outline", value: this.paintOutline},
            {text: "centered text", value: this.paintCenterText},
            {text: "bounding box", value: this.paintBoundingBox},
        ]
        const selected = options[1]
        return {selected, options, loading: false, destroyed: false}
    },
    methods: {
        handleError(err) {
            console.log({ err })
        },
        async onInit(promise) {
            this.loading = true

            try {
                await promise
            } catch (error) {
                this.$swal({
                    icon: "error",
                    text: error.message
                })
                console.error(error)
            } finally {
                this.loading = false
            }
        },
        // onDecode(data) {
        //     alert(data)
        //     console.log({data})
        // },
        paintOutline(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

                ctx.strokeStyle = "red";

                ctx.beginPath();
                ctx.moveTo(firstPoint.x, firstPoint.y);
                for (const {x, y} of otherPoints) {
                    ctx.lineTo(x, y);
                }
                ctx.lineTo(firstPoint.x, firstPoint.y);
                ctx.closePath();
                ctx.stroke();
            }
        },
        paintBoundingBox(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const {boundingBox: {x, y, width, height}} = detectedCode

                ctx.lineWidth = 2
                ctx.strokeStyle = '#007bff'
                ctx.strokeRect(x, y, width, height)
            }
        },
        paintCenterText(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const {boundingBox, rawValue} = detectedCode

                const centerX = boundingBox.x + boundingBox.width / 2
                const centerY = boundingBox.y + boundingBox.height / 2

                const fontSize = Math.max(12, 50 * boundingBox.width / ctx.canvas.width)
                console.log(boundingBox.width, ctx.canvas.width)

                ctx.font = `bold ${fontSize}px sans-serif`
                ctx.textAlign = "center"

                ctx.lineWidth = 3
                ctx.strokeStyle = '#35495e'
                ctx.strokeText(detectedCode.rawValue, centerX, centerY)

                ctx.fillStyle = '#5cb984'
                ctx.fillText(rawValue, centerX, centerY)
            }
        },
        // logErrors(promise) {
        //     console.log({ promise })
        //     promise.catch(error => {
        //         console.log({ error })
        //         this.$swal({
        //             icon: "error",
        //             text: error.message
        //         })
        //     })
        //     // promise.catch(console.error)
        // },
        async reload() {
            this.destroyed = true

            await this.$nextTick()

            this.destroyed = false
        }
    }
}
</script>

<style scoped>
.loading-indicator {
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    color: white
}
</style>
