<template>
    <svg class="w-4 " viewBox="0 0 169 211" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M168.8 84.4C168.8 146.4 84.4 210.8 84.4 210.8C84.4 210.8 0 146.5 0 84.4C0 62.0157 8.89212 40.5483 24.7202 24.7202C40.5483 8.89212 62.0157 0 84.4 0C106.784 0 128.252 8.89212 144.08 24.7202C159.908 40.5483 168.8 62.0157 168.8 84.4Z"
            fill="#EC1C24"/>
        <path
            d="M84.4 134.1C112.401 134.1 135.1 111.401 135.1 83.3999C135.1 55.3991 112.401 32.7 84.4 32.7C56.3992 32.7 33.7 55.3991 33.7 83.3999C33.7 111.401 56.3992 134.1 84.4 134.1Z"
            fill="white"/>
    </svg>
</template>
<script>
export default {
    name: 'marker-icon'
}
</script>
