<template>
    <svg fill="currentColor" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <g>
	<defs>
		<rect id="SVGID_1_" width="512" height="512"/>
	</defs>
            <clipPath id="SVGID_2_">
		<use xlink:href="#SVGID_1_" style="overflow:visible;"/>
	</clipPath>
            <path class="st0" d="M30,30h90V0H0v120h30V30z"/>
            <path class="st0" d="M392,0v30h90v90h30V0H392z"/>
            <path class="st0" d="M482,482h-90v30h120V392h-30V482z"/>
            <path class="st0" d="M30,392H0v120h120v-30H30V392z"/>
            <path class="st0" d="M61,60v150h150v-90h30V90h-30V60H61z M181,180H91V90h90V180z"/>
            <path class="st0" d="M451,450V300h-60v-30h-30v30h-90v30h30v30h-30v30h30v60H451z M331,330h90v90h-90V330z"/>
            <path class="st0"
                  d="M151,270h60v-30H61v30h60v30H91v30h30v60H91v30h30v30h150v-30h-30v-30h-30v30h-60v-30h30v-30h-30V270z"/>
            <path class="st0" d="M121,120h30v30h-30V120z"/>
            <path class="st0" d="M361,120h30v30h-30V120z"/>
            <path class="st0" d="M391,210h60V60H301v150h60v30h30V210z M331,180V90h90v90H331z"/>
            <path class="st0" d="M451,270v-30c-7.3,0-52.7,0-60,0v30H451z"/>
            <path class="st0" d="M361,360h30v30h-30V360z"/>
            <path class="st0" d="M241,330h30v30h-30V330z"/>
            <path class="st0" d="M181,360h30c0-7.3,0-52.7,0-60h-30V360z"/>
            <path class="st0" d="M211,270h30v30h-30V270z"/>
            <path class="st0" d="M91,330H61v60h30C91,382.7,91,337.3,91,330z"/>
            <path class="st0" d="M61,420h30v30H61V420z"/>
            <path class="st0" d="M241,60h30v30h-30V60z"/>
            <path class="st0" d="M241,180h30c0-7.3,0-52.7,0-60h-30V180z"/>
            <path class="st0" d="M271,240v-30h-30v60h120v-30H271z"/>
</g>
</svg>
</template>
<script>
export default {
    name: 'qr-icon'
}
</script>
