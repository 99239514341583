<template>
    <div class="loading"  >
        <div class="">
            <div class="text-center text-gray-600">
                ກຳລັງດຳເນີນການ...
            </div>
            <div class="flex">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Loader"
}
</script>

<style scoped lang="scss">


.loading {
    $colors: #7ef9ff, #89cff0, #4682b4, #0f52ba, #000080;
    display: flex;
    background: rgba(255, 255, 255, .75);
    @apply w-screen h-screen flex items-center justify-center;
    position: fixed;
    z-index: 1100;
    top: 0; right: 0; bottom: 0; left: 0;


    .dot {
        position: relative;
        width: .75rem;
        height: .75rem;
        margin: 0.5rem;
        border-radius: 50%;

        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: inherit;
            border-radius: inherit;
            animation: wave 1.5s ease-out infinite;
        }

        @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
                background: nth($colors, $i);

                &::before {
                    animation-delay: $i * 0.1s;
                }
            }
        }
    }
}

@keyframes wave {
    50%,
    75% {
        transform: scale(2.5);
    }

    80%,
    100% {
        opacity: 0;
    }
}
</style>
