<template>
    <svg class="w-6 h-6 flex-none" fill="none">
        <circle cx="12" cy="12" r="12" fill="#FECDD3"></circle>
        <path d="M8 8l8 8M16 8l-8 8" stroke="#B91C1C" stroke-width="2"></path>
    </svg>
</template>
<script>
export default {
    name: 'reject-icon'
}
</script>
