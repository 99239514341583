<template>
    <div class="w-full pb-0">
        <label class="label" v-if="label">
            {{ label }}
        </label>
        <!--        <input :type="type" :required="required" :name="name" v-model="model" class="input" id="grid-last-name"-->
        <!--               placeholder="Doe">-->

        <template v-if="options">

            <cleave :disabled="disabled" :maxlength="maxlength" :minlength="minlength" :type="type" :required="required" :name="name" v-model="model" :value="model" class="input"
                    id="grid-last-name"
                    :placeholder="placeholder" :options="options" />

        </template>

        <template v-else>

            <input :disabled="disabled" :maxlength="maxlength" :minlength="minlength" :type="type" :required="required" :name="name" v-model="model" :value="model" class="input"
                    id="grid-last-name"
                    :placeholder="placeholder"/>
        </template>

        <p v-if="message" class="message">{{ message }}</p>
    </div>
</template>

<script>


export default {
    name: "TextField",
    props: {
        label: {
            type: String,
            required: false,
            default: ""
        },
        value: {
            required: false,
            default: ""
        },
        type: {
            type: String,
            required: false,
            default: "text"
        },
        message: {
            type: String,
            required: false,
            default: ""
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String
        },
        minlength: {type: Number, default: 0},
        maxlength: {type: Number, default: 48},
        options: {
            type: Object,
        },
        disabled: {

        }
    },
    computed: {
        name() {
            if (!this.label) return ""
            return this.label.toLowerCase();
        },
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    }
}
</script>

<style scoped lang="scss">
.input {
    @apply appearance-none block w-full bg-gray-50 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-3;

    &:focus {
        @apply ring-2 ring-blue-400;
    }
}

.label {
    @apply block tracking-wide text-gray-700 text-sm font-bold mb-2;
}

.message {
    @apply text-red-500 text-xs italic;
}

input:disabled {
    @apply bg-gray-200;
}
</style>
