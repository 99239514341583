<template>
    <div>
        <Loader v-show="loading" :aria-hidden="!loading"/>
        <div v-if="showCamera"  class="h-screen w-screen bg-black relative absolute top-0"
             style="z-index: 2000; top: 0; position: fixed;">
            <div style="height: 4rem" class="p-2 flex items-center">
                <button @click="showCamera = false" style="color: white" class="bg-gray-600 p-1 rounded-full">
                    <x-icon/>
                </button>
                <div class="text-white px-4 text-center flex-1 font-bold text-lg">
                    Scan OnePay QR
                </div>
                <div style="width: 2rem">
                </div>
            </div>
            <view-finder :camera="camera" :get-qr="getQr" style="top: 4rem; width: 100%; z-index: 2000" class="absolute"/>
        </div>
        <base-layout>
            <div class="mb-4 flex justify-center items-center flex-col ">
                <logo class="w-40 md:w-64"/>
                <h1 class="text-xl font-bold text-gray-600 text-center">ຮ່ວມໃຈຫລຸດ COVID-19</h1>
                <h3 class="mt-1 text-gray-500 text-center leading-6">ເຮັດໃຫ້ຂັ້ນຕອນການ Check-in
                    ຢູ່ຮ້ານທ່ານງ່າຍຂຶ້ນດ້ວຍ <b class="text-gray-600">Check-in One</b></h3>
            </div>

            <div>
                <div class="flex">
                    <div class="compare-pane left">
                        <h6 class="font-bold text-gray-600 text-lg text-center">Check-in ແບບເກົ່າ</h6>
                    </div>

                    <div class="compare-pane right">
                        <h6 class="font-bold  text-xl text-center  flex justify-center items-center"
                            style="color: #ec1c24">
                            Check-in One
                        </h6>
                    </div>
                </div>

                <div class="flex mt-0">
                    <div class="compare-pane left flex">
                        <!--                            <new-check-in/>-->
                        <img class="mb-2 pr-1" style="object-fit: contain;"
                             :src="`${require('@/assets/images/old-check-in-02.svg')}`" alt="old-check-in-method">
                    </div>

                    <div class="compare-pane right flex">
                        <img class="mb-2 pl-1" style="object-fit: contain"
                             :src="`${require('@/assets/images/one-check-in-03.svg')}`" alt="check-in-one">
                    </div>
                </div>

                <div class="flex mt-2">
                    <div class="compare-pane left">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <reject-icon/>
                            </div>
                            <div class="ml-2 text-gray-500">
                                ຊ້າກວ່າ
                            </div>
                        </div>
                    </div>

                    <div class="compare-pane right ">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <checked-icon/>
                            </div>
                            <div class="ml-2 text-gray-500">
                                ໄວ
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex mt-3">
                    <div class="compare-pane left">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <reject-icon/>
                            </div>
                            <div class="ml-2 text-gray-500">
                                ຂໍ້ມູນຖືກເປີດເຜີຍ
                            </div>
                        </div>
                    </div>

                    <div class="compare-pane right ">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <checked-icon/>
                            </div>
                            <div class="ml-2 text-gray-500">
                                ຮັກສາຄວາມເປັນສ່ວນຕົວໄດ້ດີກວ່າ
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h2 class="font-bold text-3xl text-gray-600  mt-6 text-">
                ສ້າງປ້າຍ QR ໃຫ້ຮ້ານທ່ານ
            </h2>
            <h4 class="mt-1 text-gray-500">
                ທ່ານຈະໄດ້ຮັບຮູບປ້າຍທັນທີ ເພື່ອເອົາໄປພິມແລະຕິດໃສ່ຮ້ານ
            </h4>

            <div class="mt-4">
                <div class=" flex items-start">
                    <div class="flex flex-col items-center justify-center mr-4" style="align-self:stretch;">
                        <div style="background: #ec1c24; margin-top: .5rem"
                             class="w-3 h-3 flex-shrink-0 bg-gradient-to-tr from-green-300 to-green-500 rounded-full mb-2">
                        </div>
                        <div style="background: #ec1c24" class="h-full w-0.5 rounded flex-1 items-stretch ">
                        </div>
                    </div>
                    <div style="flex-grow: 1">
                        <h5 class="text-lg font-bold text-gray-600">
                            ຖ້າຮ້ານທ່ານມີ OnePay
                        </h5>

                        <p class="text-gray-500">
                            Scan QR OnePay ຮ້ານທ່ານ ຫລື ເລືອກຮູບ QR OnePay ເພື່ອສ້າງປ້າຍ
                        </p>
                        <div class="flex mt-4" style="flex-basis: auto; ">
                            <div class="flex-1 pr-1 ">
                                <button @click="showCamera = true"
                                        class="my-button red-gradient no-select w-full flex justify-center items-center flex-col">

                                    <qr-icon class="text-white w-12 mb-2"/>

                                    ສແກນ QR ຮ້ານ
                                </button>
                            </div>

                            <div class="flex pl-1 flex-1">
                                <label
                                    class="my-button red-gradient no-select flex-1 flex justify-center flex-col items-center"
                                >

                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                         stroke-linejoin="round" class="feather feather-image w-12 mb-2">
                                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                        <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                        <polyline points="21 15 16 10 5 21"></polyline>
                                    </svg>
                                    ເລືອກຮູບ QR ຮ້ານ
                                    <qrcode-capture
                                        v-if="false"
                                        @detect="onDetect"
                                        class="hidden"
                                        :capture="false"
                                        :multiple="false"
                                        type="file" name="myImage" accept="image/png, image/gif, image/jpeg"
                                    />
                                    <input
                                        @input="onFileChange"
                                        ref="imageInput"
                                        class="hidden"
                                        :capture="false"
                                        :multiple="false"
                                        type="file" name="myImage" accept="image/png, image/gif, image/jpeg"
                                    />

                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-8 flex">
                <div class="flex flex-col items-center justify-center mr-4" style="align-self:stretch;">
                    <div style="margin-top: .5rem" class="w-3 h-3 flex-shrink-0  rounded-full mb-2 bg-gray-200">
                    </div>
                    <div class="w-0.5 h-full rounded flex-1 items-stretch bg-gray-200">
                    </div>
                </div>
                <div class="flex-1">
                    <h5 class="text-lg font-bold text-gray-600 ">
                        ຖ້າຮ້ານທ່ານບໍ່ມີ OnePay
                    </h5>
                    <p class="text-gray-500">
                        ປ້ອນຂໍ້ມູນລຸ່ມນີ້ເພື່ອສ້າງປ້າຍ
                    </p>
                    <form @submit.prevent="sendFormDataAndDownloadFile" class="mt-3">
                        <div>
                            <text-field required label="ຊື່ຮ້ານ" v-model="customShop.name"/>
                        </div>
                        <div>
                            <text-field required label="ເບິໂທ" v-model="customShop.phone"
                                        :options="{phone: true, phoneRegionCode: 'LA'}"/>
                        </div>

                        <div>
                            <div>
                                <text-field required label="ທີ່ຢູ່" :maxlength="256" v-model="customShop.address"/>
                            </div>
                            <div class="mb-4 mt-6">
                                <div class="flex-1 text-gray-600">
                                    ເລືອກທີ່ຕັ້ງຂອງຮ້ານທ່ານໂດຍການເລືອກໃນແຜນທີ່ລຸ່ມນີ້
                                </div>
                                <!--                                    <button type="button"-->
                                <!--                                            @click="setStoreLocationToCurrentLocation"-->
                                <!--                                            class="flex items-center mt-2 mb-1 hover:shadow focus:ring-blue-200 focus:ring-2 flex-shrink-0 px-4 py-2 rounded bg-gray-200 text-gray-600">-->

                                <!--                                        <marker-icon class="mr-2"/>-->
                                <!--                                        ເລືອກທີ່ຢູ່ປັດຈຸບັນ-->
                                <!--                                    </button>-->
                            </div>
                            <div class="w-full  border relative" style="height: 26rem">
                                <button type="button"
                                        @click="setStoreLocationToCurrentLocation"
                                        style="z-index: 999"
                                        class="absolute top-1 shadow-md right-3 flex items-center mt-2 mb-1 hover:shadow focus:ring-blue-200 focus:ring-2 flex-shrink-0 px-4 py-2 rounded bg-white text-gray-600">
                                    <marker-icon class="mr-2"/>
                                    ເລືອກທີ່ຢູ່ປັດຈຸບັນ
                                </button>
                                <l-map @click="(x) => handleLMapClick(x)" class=" h-full" ref="myMap" id="map"
                                       :zoom="zoom" :center="centerMapLocation">
                                    <l-tile-layer detect-retina :url="url"
                                                  :attribution="attribution"></l-tile-layer>
                                    <l-marker draggable ref="marker" v-if="customShop.latitude && customShop.longitude"
                                              :lat-lng="pointerLocation">
                                        <l-popup ref="popup">ແຕະແຜນທີ່ເພື່ອເລືອກຈຸດທີ່ຕັ້ງຮ້ານຂອງທ່ານ</l-popup>
                                    </l-marker>
                                </l-map>
                            </div>
                        </div>

                        <div class="mt-4">
                            <label class="inline-flex items-center mt-0">
                                <input type="checkbox"
                                       class="form-checkbox h-5 w-5 text-blue-600 rounded rounded border-gray-400 border-2"
                                       v-model="customShop.vaccinerequired"><span
                                class="ml-2 text-gray-500 text-lg flex items-center">

                                    <img class="mr-1 w-8 ml-2" style="object-fit: contain;"
                                         :src="`${require('@/assets/images/green-vaccination-card1-02.svg')}`"
                                         alt="old-check-in-method">
                                    ຕ້ອງມີບັດຂຽວ</span>
                            </label>
                        </div>

                        <div class="mt-8 flex justify-end">
                            <button
                                style="/*background: #ec1c24*/"
                                class="disabled:opacity-50 px-4 py-2 red-gradient text-white  font-bold rounded shadow-md flex items-center"
                                type="submit">

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-download mr-2">
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                    <polyline points="7 10 12 15 17 10"></polyline>
                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                </svg>
                                Download ປ້າຍ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </base-layout>
        <!--        <div class=" flex justify-center">-->
        <!--            <div class="max-w-2xl w-full py-4 px-3 pb-16"></div>-->
        <!--        </div>-->

        <!--        <div :style="`width: ${signWidth}`" class="visually-hidden" style="">-->
        <!--            <div-->
        <!--                ref="sign"-->
        <!--                class="relative bg-white"-->
        <!--                :style="`width: ${signWidth}`"-->
        <!--            >-->
        <!--                <sign/>-->
        <!--                <div class="h-center z-50" style="font-size: 3rem; top: 35.75rem; font-weight: bold">-->
        <!--                    {{ shopName }}-->
        <!--                </div>-->
        <!--                <div style="top: 51.75rem" class="h-center left-0 z-50">-->
        <!--                    <div style="width: 26.25rem" v-html="qrSvg">-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>

import ViewFinder from "../components/ViewFinder";
import Loader from "../components/Loader";
import Logo from "../components/Logo";
// import Sign from "../components/Sign";
// import * as htmlToImage from 'html-to-image';
// import download from "downloadjs"
import TextField from "../components/TextField";
import QRCode from "qrcode-svg";

// import {parseEmvQrLine} from "../utils/misc"
import XIcon from "./XIcon";
import RejectIcon from "./RejectIcon";
import CheckedIcon from "./CheckedIcon";
import QrIcon from "./QrIcon";
import MarkerIcon from "./MarkerIcon";
import {Decoder} from '@nuintun/qrcode';

import {Icon} from 'leaflet';
import BaseLayout from "../components/BaseLayout";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    // iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    // iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconRetinaUrl: require('@/assets/images/pin-2x.png'),
    iconUrl: require('@/assets/images/pin-sm.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

require('cleave.js/dist/addons/cleave-phone.la')

const centerLocation = {lat: 17.9542264787966, lng: 102.63758423810442};


export default {
    name: 'Home',
    data() {
        return {
            selected: "onepay",
            signWidth: "80rem",
            showCamera: false,
            qrValue: "",
            shopName: "",
            imageUrl: "",
            myType: "onepay",
            customShop: {
                name: "", address: "", phone: "", latitude: null, longitude: null, vaccinerequired: 0
            },
            camera: 'auto',
            centerMapLocation: centerLocation,
            currentLocation: {
                lat: 0,
                lng: 0
            },
            loading: false,

            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            zoom: 13,
            center: [51.505, -0.159],
            markerLatLng: [51.504, -0.159]
        }
    },
    mounted() {
        this.handleGetCurrentLocation()

    },
    components: {
        BaseLayout,
        MarkerIcon, QrIcon, CheckedIcon, RejectIcon, XIcon, TextField, ViewFinder, Logo, /*Sign,*/ Loader},
    computed: {
        pointerLocation() {
            const {customShop} = this
            if (!customShop.latitude || !customShop.longitude) {
                return undefined;
            }
            return [parseFloat(customShop.latitude), parseFloat(customShop.longitude)]
        },
        downloadButtonDisabled() {
            return (this.qrValue.trim() === "") || (this.shopName.trim() === "")
        },
        qrSvg() {
            if (!this.qrValue) return ""
            return new QRCode({
                content: this.qrValue,
                join: false,
                predefined: false,
                container: "svg-viewbox",
                padding: 0,
            }).svg();
        },
    },
    watch: {
        showCamera(val) {
            this.camera = val ? 'auto' : 'off'
            document.querySelector('body').classList.toggle('noscroll', val);
        },
        currentLocation(currentLocation) {
            console.log({currentLocation})
        },
        loading(loading) {
            document.querySelector('body').classList.toggle('noscroll', loading);
        },
        customShop: {
            handler(val, oldVal) {
                const {latitude, longitude} = val
                const {latitude: oldLatitude, longitude: oldLongitude} = oldVal

                if ((latitude && longitude) && (!oldLongitude || !oldLatitude)) {
                    this.$nextTick(() => {
                        console.log({marker: this.$refs.marker})
                        this.$refs.marker.mapObject.openPopup()
                    })
                }
            },
            deep: true
        }
    },
    methods: {
        setStoreLocationToCurrentLocation() {
            const {currentLocation} = this
            if (currentLocation) {
                this.centerMapLocation = currentLocation
                this.currentLocation = currentLocation
                const {lng: longitude, lat: latitude} = currentLocation
                this.customShop = {
                    ...this.customShop,
                    latitude,
                    longitude
                }

                const {lat, lng} = currentLocation

                this.$refs.myMap.mapObject.panTo([parseFloat(lat), parseFloat(lng)])

                // this.centerMapLocation = [parseFloat(lat), parseFloat(lng)]
            } else {
                this.$toast("ບໍ່ສາມາດດຶງຂໍ້ມູນທີ່ຢູ່ປັດຈຸບັນ", {
                    type: "warning",
                    timeout: 5000,
                })
                console.error('CANNOT GET CURRENT LOCATION')
            }
        },
        handleGetCurrentLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        console.log({position})
                        this.currentLocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }
                        this.$nextTick(() => {
                            this.setStoreLocationToCurrentLocation()
                        })
                    },
                    () => {
                        this.handleLocationError(true,);
                    }
                );
            } else {
                // Browser doesn't support Geolocation
                this.handleLocationError(false,);
            }
        },
        handleLocationError(browserHasGeolocation) {
            this.currentLocation = null
            console.log(browserHasGeolocation
                ? "Error: The Geolocation service failed."
                : "Error: Your browser doesn't support geolocation.")
            const message = browserHasGeolocation
                ? "ບໍ່ສາມາດດຶງຂໍ້ມູນທີ່ຢູ່ປັດຈຸບັນຂອງທ່ານລອງກວດຄືນວ່າທ່ານອະນຸຍາດໃຫ້ເຂົ້າເຖິງທີ່ຢູ່ປັດຈຸບັນຂອງທ່ານໃນ Browser ຫລື ຍັງ"
                : "ບໍ່ສາມາດດຶງຂໍ້ມູນທີ່ຢູ່ປັດຈຸບັນຂອງທ່ານ Browser ຂອງທ່ານບໍ່ຮອງຮັບ"

            this.$toast(message, {
                type: "warning",
                timeout: 5000
            })


            this.customShop = {
                ...this.customShop,
                latitude: null,
                longitude: null
            }

            //
            this.$nextTick(() => {
                const {lat, lng} = centerLocation
                this.$refs.myMap.mapObject.panTo([parseFloat(lat), parseFloat(lng)])

            })


        },
        handleLMapClick(data) {
            this.customShop = {
                ...this.customShop,
                latitude: data.latlng.lat,
                longitude: data.latlng.lng
            }
        },
        handleMapClick(x) {
            console.log(x)
            this.customShop = {
                ...this.customShop,
                latitude: x.latLng.lat(),
                longitude: x.latLng.lng()
            }

            console.log({long: x.latLng.lng()})
            console.log({lat: x.latLng.lat()})
        },
        clearQrData() {
            this.qrValue = "";
            this.shopName = ""
        },
        getQr(data) {
            try {
                setTimeout(async () => {
                    this.loading = true
                    this.showCamera = false

                    await this.generateSignData(data)
                    /*  // console.log(parseEmvQrLine)
                      // console.log("QRRRRRR", this.qrValue, this.shopName)
                      // this.loading = false

                      // const q = parseEmvQrLine(data)
                      // const bcelqr = parseEmvQrLine(q[33])
                      // const mcid = bcelqr[2]
                      // console.log({q, bcelqr, mcid})
                      // await this.$connector.getMerchantInfo(mcid)
                      // await this.getImage()*/
                }, 500)
            } catch (e) {
                this.loading = false
                this.$connector.showError(e)
            }
        },
        async generateSignData(qrString) {
            try {
                const res = await this.$connector.requestQrByOnePay(qrString)

                this.qrValue = res.qr
                this.shopName = res.name
                if (res && res.qr && res.name) {
                    await this.getImage(res.qr, res.name)
                    return
                }
                this.$connector.showError("ບໍ່ພົບ QR OnePay ນີ້ໃນລະບົບ")
                this.loading = false
            } catch (error) {
                this.loading = false
                this.clearQrData()
                this.$connector.showError(error);
            }
        },
        async onDetect(data) {
            try {
                const {content, imageData, location} = await data
                console.log({content, imageData, location})
                alert(JSON.stringify(data))
                if (!content) return this.$connector.showError("ບໍ່ພົບ QR Code ໃນຮູບ")
                this.loading = true;
                await this.generateSignData(content)
            } catch (error) {
                this.loading = false
                this.clearQrData()
                this.$connector.showError(error)
            }
        },

        async onFileChange() {
            const image = this.$refs.imageInput.files[0]

            const qrcode = new Decoder();

            qrcode
                .scan(URL.createObjectURL(image))
                .then(async (result) => {
                    const {data} = result;
                    this.loading = true;
                    await this.generateSignData(data)
                })
                .catch(error => {
                    console.error(error);
                    this.$connector.showError("ບໍ່ພົບ QR Code ໃນຮູບ")
                });

            // try {
            //     const {content, imageData, location} = await data
            //     console.log({content, imageData, location})
            //     alert(JSON.stringify(data))
            //     if (!content) return this.$connector.showError("ບໍ່ພົບ QR Code ໃນຮູບ")
            //     this.loading = true;
            //     await this.generateSignData(content)
            // } catch (error) {
            //     this.loading = false
            //     this.clearQrData()
            //     this.$connector.showError(error)
            // }
        },
        async getImage(qrValue, shopName) {
            // https://bcel.la:8083/checkinoneqr/generate.php?qr=00020101021138420005LAOQR0104BCEL0207CHECKIN0310MFWFS9DK02&name=Attapue%20Treasury%20Department
            const url = "https://bcel.la:8083/checkinoneqr/generate.php?";
            const params = encodeURI(`qr=${qrValue}&name=${shopName}`)
            window.location.href = url + params
            this.loading = false

            this.$nextTick(() => {

                this.$router.push("/success?" + params)
            })


            // this.$nextTick(async () => {
            //     if (!qrValue || !shopName) return this.$connector.showError("ບໍ່ພົບຂໍ້ມູນໃຫ້ດາວໂຫລດ")
            //     const signImage = this.$refs.sign;
            //     const dataUrl = await htmlToImage.toPng(signImage, {quality: 1})
            //
            //     download(dataUrl, this.shopName.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.png', "image/png")
            //
            //     this.loading = false
            //
            //     this.$toast("ການສ້າງປ້າຍຂອງຮ້ານທ່ານສຳເລັດ", {
            //         type: "success",
            //         timeout: 5000,
            //     })
            // })
        },
        async sendFormDataAndDownloadFile() {

            if (!this.customShop.latitude || !this.customShop.longitude) return this.$toast("ເລືອກທີ່ຕັ້ງຮ້ານກ່ອນ", {
                type: "warning",
                timeout: 3000,
            });

            if (this.customShop.phone.trim().length < 7) return this.$toast("ກະລຸນາປ້ອນເບີໂທໃຫ້ຖືກຕ້ອງ", {
                type: "warning",
                timeout: 3000,
            })

            this.loading = true

            try {
                const res = await this.$connector.requestQrByForm({
                    ...this.customShop,
                    vaccinerequired: this.customShop.vaccinerequired ? 1 : 0
                })

                if (res && res.qr && res.name) {
                    this.qrValue = res.qr
                    this.shopName = res.name
                    return await this.getImage(res.qr, res.name)
                }

                this.loading = false
                return this.$connector.showError("ບໍ່ພົບ QR OnePay ນີ້ໃນລະບົບ")
            } catch (error) {
                this.loading = false
                this.clearQrData()
                this.$connector.showError(error)
            }
        }
    }
}
</script>

<style lang="scss">
.my-button {
    //background: #ec1c24;
    @apply  px-3 py-3 text-white rounded-md shadow-md font-bold;
}

.red-gradient {
    @apply bg-gradient-to-tr from-red-600 to-red-400;
}

.visually-hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
    z-index: -1
}

.h-center {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.active {
    background: white;
    color: #63b3ed;
}

.compare-pane {
    @apply w-1/2 ;

}

.compare-pane.left {
    @apply pr-2;
}

.compare-pane.right {
    @apply pl-2;
}

</style>
